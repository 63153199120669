<template>
  <section class="privacy">
    <div class="privacy__content">
      <h1>
        Privacy Policy
      </h1>
      <h3>
        Website Privacy Statement
      </h3>
      <p>
        This Privacy Statement relates to the website located at Jumpfeed (this
        “Site”) and which is owned and operated by Jumpfeed, LLC (together with
        its affiliates, individually or collectively, “Jumpfeed”). Your privacy
        is important to Jumpfeed. We want you to understand how we collect, use,
        share and store your information when you use this Site. By using this
        Site, you accept and agree to the practices this Privacy Statement
        describes. IF YOU DO NOT AGREE, PLEASE DO NOT USE THIS SITE.
      </p>
      <h3>
        Information We Collect
      </h3>
      <p>
        When you use this Site, we may collect and use personally identifiable
        information and other information about you. “Personally identifiable
        information” includes your name, address, email address, telephone
        number and other similar information that can be used to individually
        identify you, directly or indirectly, and that is not otherwise publicly
        available. For example, we may collect the following information:
      </p>
      <p>
        <b>-</b> Information that you provide on this Site or Linked Sites by
        filling in forms (including credit application forms), opening an
        account or purchasing any product or service on or through this Site,
        including your name, address, email address, telephone number or other
        information; <br /><b>-</b> Information that you provide when you
        communicate with us or other parties who provide products or services on
        or through this Site, or other users of this Site through this Site for
        any reason, including information in emails you send us or advertisers
        on this Site, or submissions you may make on our Site; and <br /><b
          >-</b
        >
        Details of your visits to this Site or Linked Sites and the resources
        that you access, including, but not limited to, traffic data, location
        data, weblogs and other communication data, including your IP address,
        browser type, device identification data, the date and time you accessed
        or left the Site, and the pages you viewed.
      </p>
      <p>
        In addition, we may combine information about your visits to this Site
        and/or any Linked Site with personally identifiable information about
        you (this combination is sometimes called “PII Merger”) so that we can
        better understand your interests and serve you better, such as by
        tailoring your experience within this Site or a Linked Site or
        delivering relevant advertisements to you as described in “Use of
        Tracking Technologies” below. You can opt out of having this occur by
        opting out of interest-based advertising as described in “Your Privacy
        Options” below. We do not retrieve personally identifiable information
        from the computer or other device that you use to visit this Site or any
        Linked Site.
      </p>
      <h3>
        Use of Tracking Technologies
      </h3>
      <p>
        We use cookies, pixel tags, server logs and other Internet technologies
        to collect information about you and your use of this Site, and we may
        additionally collect data about your visits to other websites. These
        technologies do not provide us with your personal information, but they
        do allow us to track and record your activities on this Site and other
        websites. In addition to information collected by us, we may allow our
        related companies or other third parties to use cookies and web beacons
        to track non-personally identifiable information about visitors to this
        Site.
      </p>
      <p>
        A “cookie” is a small computer file that is sent to your computer when
        you visit a website. When you visit this Site again (or certain other
        cooperating websites), the cookie allows us to recognize your browser.
        Cookies may store user preferences and other information. A pixel tag
        (also known as a “web beacon”) is a type of technology placed on a
        website or within the body of an email for the purpose of tracking a
        user’s activity on websites, or when emails are opened or accessed, and
        is often used in combination with cookies. To learn more about the
        details, you may visit www.allaboutcookies.org and/or other resources.
      </p>
      <h3>
        Your Privacy Options
      </h3>
      <p>
        <b>Decline or Block Cookies and Pixel Tags.</b> You can modify the
        security settings on your computer, tablet or mobile device and reset
        your browser to approve or reject cookies on a case-by-case basis, block
        cookies from certain sites, or even refuse all cookies. Similarly, you
        can disable pixel tags by changing your browser settings to omit images
        and disable Javascript, or use commercial software packages that can
        omit pixel tags and most advertisements. Please be aware, however, if
        you “turn off,” purge or disable cookies or pixel tags, you may not be
        able to use all of the features, functions or services available on this
        Site.
      </p>
      <p>
        <b>Opt Out of Interest-Based Advertising and PII Merger.</b> Jumpfeed,
        LLC (“Jumpfeed”) is a participant in the Digital Advertising Alliance
        (“DAA”) and supports DAA’s Self-Regulatory Principles for Online
        Behavioral Advertising, which means that you have options and choices
        about our collection and use of your data for online interest-based
        advertising. Jumpfeed is also a member of the Interactive Advertising
        Bureau (“IAB”) and adheres to (and causes its affiliates, including
        Jumpfeed, to adhere to) the IAB Code of Conduct. For more information
        about interest-based information tracking and to opt out of
        interest-based advertising categories by Jumpfeed or Jumpfeed, you may
        click on the DAA’s AdChoices icon located at the bottom of this page or
        visit http://www.aboutads.info/choices/. By opting out of interest-based
        advertising by Jumpfeed, you will also be opting out of interest-based
        advertising by Jumpfeed. Please note that if you choose to opt out of
        having your information used to deliver advertisements tailored to your
        interests, you will continue to see advertisements delivered by Jumpfeed
        or Jumpfeed, but these advertisements may not be as relevant to you.
      </p>
      <p>
        <b>Do-Not-Track Request.</b> This Site currently does not respond to “do
        not track” or similar signals.
      </p>
      <h3>
        Use of Your Information
      </h3>
      <p>
        <b>General.</b> We use the information we collect from you on this Site
        primarily to enable us to effectively provide our products and services,
        including:
      </p>
      <p>
        <b>-</b> To provide you the information, products or services offered on
        or through this Site; <br /><b>-</b> For our internal purposes such as
        to monitor and analyze Site traffic and usage patterns to help improve
        this Site or product or service offered on or through this Site, better
        understand our customers’ needs, or to develop new offerings in which
        you may be interested; <br /><b>-</b> To compile statistics and reports
        regarding the users of this Site, products and services, which may
        include combining the personal information you provide with information
        we obtain from business partners or other companies; <br /><b>-</b> To
        deliver interest-based content and advertising to you that is targeted
        to the interests we identify through your online activities; <br /><b
          >-</b
        >
        To share with our related companies or trusted third parties so that
        they may assist us with our use of the information as described above;
        and <br /><b>-</b>
        To share with trusted third parties, such as advertising networks, for
        use in delivering interest-based advertising to you.
      </p>
      <p>
        In addition, if you complete a “Request More Information” form, we will
        share the information you provide with the dealership that is selling or
        leasing the vehicle in which you are interested (and we may also share
        that information with the dealership’s manufacturer), and when you apply
        for financing, we will share the information you provide with the
        relevant dealership and/or with one or more potential financing sources.
      </p>
      <p>
        <b>Other Circumstances.</b> We may disclose information about you,
        including personally identifiable information, if we have a good faith
        belief that disclosure is necessary to: (i) comply with applicable laws,
        regulations, legal process (such as a subpoena), or government requests,
        or to respond to claims that any such data violates the rights of
        others; (ii) enforce the Terms of Use for this Site, including
        investigation of potential violations of the Terms of Use, or to detect,
        prevent, or otherwise address fraud, security, or technical issues; or
        (iii) protect against harm to the rights, property, or safety of
        Jumpfeed, its affiliates, our customers, users, or the public as
        required or permitted by law. In addition, we may disclose your personal
        information to third parties if we are involved in a merger,
        acquisition, or sale of any or all of our business and/or our assets to
        a third party, provided that they agree to abide by the terms and
        conditions of this Privacy Statement.
      </p>
      <h3>
        Security
      </h3>
      <p>
        We use commercially reasonable physical, electronic, and managerial
        procedures to safeguard and secure the information we collect on this
        Site. These procedures may include, but are not limited to, facility
        access control, firewalls, encryption, intrusion detection, and Site
        monitoring. However, no data protection procedures are entirely
        infallible. As a result, while we strive to protect your personally
        identifiable information, we cannot guarantee that it will be 100%
        secure. Your transmission of your data to this Site is done entirely at
        your own risk.
      </p>
      <h3>
        Third Party Websites and Social Media Features
      </h3>
      <p>
        This Site may contain links to third party websites (“Linked Site”).
        Jumpfeed provides these Linked Sites solely for your convenience, and
        does not control or endorse any of them. Jumpfeed cannot be responsible
        for the content, security, or privacy policies of such Linked Sites. By
        accessing other third party websites or applications through this Site,
        you are consenting to the terms and privacy policies of those websites.
        It is possible that other parties may collect personally identifiable
        information about your online activities over time and across different
        web sites when you use Jumpfeed’s Site. Our Site may include social
        media features, such as the Facebook “Like” button. These features may
        collect your IP address and the website pages you are visiting, and may
        set a cookie to enable the feature to function properly. Social media
        features are either hosted on a third party website or hosted directly
        on this Site. Your interactions with these features are governed by the
        privacy statement of the entity providing them.
      </p>
      <h3>
        International Visitors
      </h3>
      <p>
        This Site is hosted and operated in the United States (“US”) and
        elsewhere in the world except Europe. It is not intended to be used by
        users in, or to offer products or services to residents of, Europe. By
        using the Site, you are consenting to the transfer of any information
        related to your use of this Site (including your personal information)
        to the US or to another jurisdiction other than your own. If you are
        accessing this Site from outside the US, please be advised that US law
        (or the law of the jurisdiction ultimately hosting this Site or the
        related content) may not offer the same privacy protections as the law
        of your jurisdiction.
      </p>
      <h3>
        Your California Privacy Rights
      </h3>
      <p>
        If you are a California resident, you have the right under California
        law to request companies with whom you have an established business
        relationship to provide certain information about the companies’ sharing
        of personal information with third parties for direct marketing purposes
        during the past year. If you are a California resident and wish to
        request further information about our compliance with this law or have
        questions or concerns about our privacy practices, you may visit our
        supplemental privacy notice and consumer rights statement or contact us
        using the contact information set forth below. If your information has
        been submitted on a form and you wish to delete or opt out, you can
        visit our User Privacy Data Request page
      </p>
      <h3>
        Children’s Privacy
      </h3>
      <p>
        This Site is solely for use by businesses and by people who are at least
        18 years old. By using this Site, you affirm that you are at least 18
        years of age. We are not liable for any damages that may result from a
        user’s misrepresentation of age. Under no circumstances may anyone under
        13 years of age use this Site, or submit any information, including
        personally identifying information, on this Site. Parents or legal
        guardians of children under the age of 13 cannot agree to these terms on
        their behalf in order to overwrite this paragraph.
      </p>
      <h3>
        Privacy Statement Updates
      </h3>
      <p>
        We will update this Privacy Statement from time to time, and any changes
        will take effect immediately after they are posted on this Site.
        Collection and use of your information from that point on will be
        subject to the updated Privacy Statement. Your continued use of the Site
        will be deemed your agreement to the updated Privacy Statement. If you
        do not agree with the changes, you should stop using the Site and notify
        us that you do not want your information used in accordance with the
        changes. We will specify the date of the most recent update in the “Last
        Date for Updates” section of this Privacy Statement. If you need a copy
        of any previous version(s) of this Privacy Statement, you may contact us
        at the address below.
      </p>
      <h3>
        Contacing Us
      </h3>
      <p>
        If you have any questions, comments, or complaints regarding Jumpfeed’
        or Jumpfeed’s privacy practices or this Privacy Statement please do not
        hesitate to contact us by email at support@Jumpfeed.com or by writing
        to:
      </p>
      <br />
      <p>
        Jumpfeed, LLC<br />
        c/o Jumpfeed, LLC<br />
        2027 W. Division St. #185<br />
        Chicago, IL 60622<br /><br />
        This Privacy Statement was last updated on January 4th, 2021.<br />
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo: {
    titleTemplate: "%s | Privacy Policy",
    link: [{ rel: "canonical", href: "https://www.jumpfeed.com/privacy" }]
  }
};
</script>

<style scoped lang="scss">
.privacy {
  background: $base;
  color: $primary;
  margin-top: $header-size;

  .privacy__content {
    max-width: 900px;
    margin: 0 auto;
    padding: $gap-large;
    font-size: 0.7em;

    p {
      font-size: 0.9em;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1em 0;
  }
}
</style>
